<template>
  <div class="login full-layout">
    <div class="login__container">
      <div class="login__image-block">
        <img alt="" class="login__image" src="../../assets/logo.png" />
        <p class="login__description-block">Такой email не зарегистрирован. Обратитесь к администратору.</p>
      </div>

      <div class="login__inputs-block">
        <div class="login__inputs-block__buttons">
          <zem-button>Написать администратору</zem-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZemInput from '../../components/ui/ZemInput.vue'
import ZemButton from '../../components/ui/ZemButton.vue'
import ZemLink from '../../components/ui/ZemLink.vue'
import User from '@/models/user'

export default {
  name: 'Login',
  components: {
    ZemInput,
    ZemButton,
    ZemLink,
  },
  data() {
    return {
      user: new User('', '', ''),
      loading: false,
      message: '',
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.replace('/profile')
    }
  },

  methods: {
    handleLogin() {
      this.loading = true
      let isValid = true
      // this.$validator.validateAll().then(isValid => {
      if (!isValid) {
        this.loading = false
        return
      }
      if (this.user.email && this.user.password) {
        this.$store.dispatch('auth/login', this.user).then(
          () => {
            this.$router.replace('/partner-orders')
            this.$store.dispatch('user/profile')
          },
          error => {
            this.loading = false
            this.message = (error.response && error.response.data) || error.message || error.toString()
          }
        )
      }
      // });
    },
  },
}
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
  height: 70%;

  &__container {
    width: 240px;
    padding: 25px;
    background: #ffffff;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
      0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }

  &__description-block {
    color: #9ba6b2;
    margin-top: 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    text-align: center;
  }

  &__image {
    margin-bottom: 26px;
    height: 30px;
  }

  &__image-block {
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    color: $color-mine-shaft;
    justify-content: center;
    align-items: center;
    margin-bottom: 22px;
  }

  &__inputs-block {
    width: 100%;

    &__buttons {
      margin-top: 12px;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .zem-input {
      margin-bottom: 16px;
    }
  }
}
</style>
